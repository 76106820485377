<template>
	<div id="qrcode_auth">
		<div class="logo">
			<img :src="$system_.state.applic_logo" alt="logo">
		</div>
	</div>
</template>

<script type="text/javascript">
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive
	} from 'vue'
	export default defineComponent({
		name: 'QrcodeAuth',
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 获取当前路由含带的参数信息
			const thisRouteParam = ctx.$route.query

			// 判断是否存在授权登录信息
			if (thisRouteParam.code && thisRouteParam.state) {
				// 请求企业微信登录
				ctx.$request_.post(ctx.$api_.state.Home.Login.login_qywork_auth.url, thisRouteParam)
					.then((respon) => {
						if (respon.Code === ctx.$code_.state.success) {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'success'
							})

							if (ctx.$api_.state.CurrentPath) {
								let CurrentPath = JSON.parse(ctx.$api_.state.CurrentPath);
								ctx.$router.push({
									path: CurrentPath.path,
									query: CurrentPath.query
								})

								ctx.$api_.commit('setCurrentPath', '')
							} else {
								ctx.$router.push({
									name: 'Home'
								})
							}
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})

							ctx.$router.push({
								name: 'SignIn'
							})
						}
					})
			} else {
				ctx.$router.push({
					name: 'SignIn'
				})
			}
		}
	})
</script>

<style lang="less">
	#qrcode_auth {
		width: 100%;
		height: 100%;
		background: url(https://z.ygddzy.cn/yc/work/login_bg.jpg) no-repeat;
		background-size: 100%;

		.logo {
			position: absolute;
			top: 1%;
			left: 1%;
			width: 10.4vw;

			img {
				width: 100%;
				font-size: 0.8vw;
				color: #ffffff;
			}
		}
	}
</style>
